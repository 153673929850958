import React from 'react'
import * as footerStyles from './footer.module.css'
import { FaFacebookSquare } from 'react-icons/fa';
import { FaYoutubeSquare } from 'react-icons/fa';
import { FaVimeoSquare } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.container}>
                <div className={`${footerStyles.row} ${footerStyles.contentrow}`}>
                    <div className={footerStyles.section}>
                        <h5>Meist</h5>
                        <p>Oleme kliendisõbralik videoproduktsioonifirma, kes pakub kvaliteetseid videolahendusi eraisikutele ja ettevõtetele.</p>
                        <div className={footerStyles.mediacontainer}>
                            <a href='https://www.facebook.com/poolusfilm' aria-label='Facebook'><FaFacebookSquare className={footerStyles.mediaicons} /></a>
                            <a href='https://www.youtube.com/channel/UC87CR5Tp4RlHaE2xuf7u43w' aria-label='Youtube'><FaYoutubeSquare className={footerStyles.mediaicons} /></a>
                            <a href='https://vimeo.com/poolusfilm' aria-label='Vimeo'><FaVimeoSquare className={footerStyles.mediaicons} /></a>
                        </div>
                    </div>
                    <div className={footerStyles.section}>
                        <h5>Teenused</h5>
                        <ul>
                            <li>Reklaamvideod</li>
                            <li>Üritusvideod</li>
                            <li>Pulmavideod</li>
                            <li>Montaaž</li>
                            <li>Videoturundus</li>
                        </ul>
                    </div>
                    <div className={footerStyles.section}>
                        <h5>Kontakt</h5>
                        <p>Võta meiega ühendust igal ajal!</p>
                        <ul>
                            <li className={footerStyles.email}>
                                <a href='mailto:poolusfilm@gmail.com'><FaEnvelope className={footerStyles.contact} />
                                poolusfilm@gmail.ee
                                </a>
                            </li>
                            <li className={footerStyles.number}>
                                <FaPhone className={footerStyles.contact} />
                                51925191
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`${footerStyles.row} ${footerStyles.copyrightrow}`}>
                    <p>Copyright © 2022 Poolusfilm</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer