import React, { useState } from 'react'
import { Link } from 'gatsby'
import * as navStyles from './navbar.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaBars } from 'react-icons/fa'

const Navbar = () => {
    const data = useStaticQuery(query)
    const logo = getImage(data.contentfulHeader.logo)
    const [show, setShow] = useState(false)
    
    return (
        <nav className={navStyles.navbar}>
            <div className={navStyles.navcenter}>
                <div className={navStyles.navheader}>
                    <Link to='/'>
                        <GatsbyImage image={logo} alt='Poolusfilm'/>
                    </Link>
                    <button className={navStyles.navbtn} onClick={() => setShow(!show)}>
                        <FaBars />
                    </button>
                </div>
                <div className={show ? `${navStyles.navlinks} ${navStyles.showlinks}` : `${navStyles.navlinks}`}>
                    <Link to='/meist' className={navStyles.navlink} activeStyle={{ opacity: '1' }}>Meist</Link>
                    <Link to='/portfolio' className={navStyles.navlink} activeStyle={{ opacity: '1' }}>Portfolio</Link>
                    <Link to='/kontakt' className={navStyles.navlink} activeStyle={{ opacity: '1' }}>Kontakt</Link>
                </div>
            </div>
        </nav>
    )
}

export const query = graphql`
  {
    contentfulHeader {
      logo {
        gatsbyImageData(height: 45, width: 164)
      }
    }
  }
`

export default Navbar