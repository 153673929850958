// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--ov5Kw";
export var container = "footer-module--container--8eueV";
export var row = "footer-module--row--cC8eo";
export var section = "footer-module--section--AqC7d";
export var contentrow = "footer-module--contentrow--u31I2";
export var copyrightrow = "footer-module--copyrightrow--Hdi9c";
export var email = "footer-module--email--tEVoe";
export var number = "footer-module--number--TbzdR";
export var contact = "footer-module--contact--lsqOm";
export var mediacontainer = "footer-module--mediacontainer--MacU0";
export var mediaicons = "footer-module--mediaicons--cbHcL";